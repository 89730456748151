import { useNavigate } from "react-router-dom";
import { useSidebar } from "../../Hooks/useSidebar";
import { IconType } from "react-icons";

interface Props {
    path: string | string[];
    Icon: IconType;
    label: string;
    closeSideBar?: VoidFunction;
}

const NavItem: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const { getClassName } = useSidebar();
    const { path, Icon, label, closeSideBar } = props;

    const onClick = () => {
        if (closeSideBar) closeSideBar();

        if (typeof path === "string") {
            navigate(path);
        } else {
            navigate(path[path.length - 1]);
        }
    }

    return (
        <div 
            className={`px-2 py-3 w-100 d-flex flex-row align-items-center justify-content-start ${getClassName(path)}`}
            onClick={onClick}
        >
            <span className="icon"><Icon size={28} className="icon" /></span>
            <span className="title">{label}</span>
        </div>
    )
}

export default NavItem;