import React, { useState } from "react";
import { AiOutlineMail, AiFillEyeInvisible, AiFillEye } from "react-icons/ai";

import InputField from "../Components/InputField";
import { Link } from "react-router-dom";
import { ROUTES } from "../Router/routes";
import { useAuth } from "../Hooks/useAuth";
import { LoginInput } from "../Interfaces/Auth";
import AnimateHeight from "react-animate-height";
import AppButton from "../Components/AppButton";

interface PasswordTogglerProps {
    visible: boolean;
    toggle: VoidFunction;
}
const PasswordToggler: React.FC<PasswordTogglerProps> = ({ visible, toggle }) => {

    return (
        <div className="password-toggler w-100 h-100 d-flex justify-content-center align-items-center" onClick={toggle}>
            { visible ? <AiFillEyeInvisible /> : <AiFillEye />}
        </div>
    );
}

export const Login: React.FC = () => {
    const { loginForm, handleLogin, loginLoading, loginError } = useAuth();

    const [visible, setVisible] = useState(false);
    const toggle = () => setVisible(!visible);

    const handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
        loginForm.onChange(e.target.name as keyof LoginInput, e.target.value);
    }

    const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();
        handleLogin();
    }

    return (
        <div className="bfs-login-page">
            <div className="container p-3 p-md-5 w-100 my-4">
                <h3>Sign In</h3>

                <p>Sign in to manage your bookings, reschedule, edit and more</p>

                <form onSubmit={handleSubmit} className="container mx-auto my-4 login-form">
                    <div className="row">
                        <div className="col-12 mb-3">
                            <InputField
                                label="Email"
                                error={loginForm.formErrors.email}
                                name="email"
                                onChange={handleChange}
                                value={loginForm.form.email}
                                placeholder="Eg: example@bfscleaners.com" 
                                prepend={<AiOutlineMail />}
                            />
                        </div>

                        <div className="col-12 mb-3">
                            <InputField
                                label="Password"
                                error={loginForm.formErrors.password}
                                name="password"
                                onChange={handleChange}
                                value={loginForm.form.password}
                                placeholder="Password" 
                                type={visible ? "text" : "password"}
                                prepend={<PasswordToggler visible={visible} toggle={toggle} />}
                            />
                        </div>
                    </div>

                    <AnimateHeight 
                        height={Boolean(loginError) ? "auto" : 0}
                        duration={300}
                    >
                        <div className="alert alert-danger" role="alert">
                            {loginError}
                        </div>
                    </AnimateHeight>

                    
                    
                    <div className="d-flex w-100 flex-row justify-content-between align-items-center flex-wrap">
                        <AppButton title="Sign In" loading={loginLoading} />

                        <Link to={ROUTES.forgot_password}>Forgot password?</Link>
                    </div>
                </form>
            </div>
        </div>
    );
}