import React from "react";
import BookOnlineForm from "../Components/BookOnlineForm";
import BookingSummary from "../Components/BookingSummary";

export const BookOnline: React.FC = () => {
    return (
        <>
            <div className="bfs-book-page">
                <h1>Book Online Now!</h1>

                <div className="container p-3 p-md-5 my-4 d-flex flex-row flex-wrap w-100">
                    <div className="left">
                        <BookOnlineForm />
                    </div>

                    <div className="right">
                        <BookingSummary />
                    </div>
                </div>
            </div>
        </>
    )
}