import { IconType } from "react-icons";
import { BfsServices } from "../Interfaces/Booking";
import { MdCleaningServices } from "react-icons/md";
import { ImBoxRemove } from "react-icons/im";
import { BsHouseDownFill } from "react-icons/bs";
import { BiSolidCabinet, BiSolidFridge } from "react-icons/bi";
import { GiChickenOven } from "react-icons/gi";
import { FcFilingCabinet } from "react-icons/fc";
import { FaKitchenSet } from "react-icons/fa6";
import { RiArchiveDrawerLine } from "react-icons/ri";

interface ExtraServiceProp {
    title: string;
    service: BfsServices;
    icon: IconType;
    single?: boolean;
}

export const EXTRA_SERVICES: ExtraServiceProp[] = [
    {
        title: "Deep cleaning",
        service: "deep_cleaning",
        icon: MdCleaningServices
    },
    {
        title: "Move cleaning",
        service: "move_clean",
        icon: ImBoxRemove
    },
    {
        title: "Finished basement",
        service: "finished_basement",
        icon: BsHouseDownFill
    },
    {
        title: "Inside fridge",
        service: "inside_fridge",
        icon: BiSolidFridge,
        single: false
    },
    {
        title: "Inside oven",
        service: "inside_oven",
        icon: GiChickenOven,
        single: false
    },
    {
        title: "Inside kitchen cabinets",
        service: "inside_kitched_cabinets",
        icon: BiSolidCabinet,
        single: false
    },
    {
        title: "Inside bathroom cabinets",
        service: "inside_bathroom_cabinets",
        icon: FcFilingCabinet,
        single: false
    },
    {
        title: "Dishes",
        service: "dishes",
        icon: FaKitchenSet
    },
    {
        title: "Change linens",
        service: "change_linens",
        icon: RiArchiveDrawerLine,
        single: false
    }
]