import { useMemo } from "react";

import { getExtraServicePrice, getTipsPrice, validateForm } from "../Utils";
import { useAppSelector } from "./useAppSelector";
import { useBookingActions } from "./useReduxActions";
import { Validator } from "../Interfaces/Validator";
import { BookingInput } from "../Interfaces/Booking";
import { emptyValidator } from "../Utils/validators/emptyValidator";
import { emailValidator } from "../Utils/validators/emailValidator";
import checkoutService from "../Services/CheckoutService";
import userService from "../Services/UserService";
import useApi from "./useApi";

const AMOUNT_PER_FEET = 0.18;
const AMOUNT_PER_EXTRA_BEDROOM = 30;
const AMOUNT_PER_EXTRA_BATHROOM = 25.99; 

const STEP_TWO_KEYS: (keyof BookingInput)[] = [
    "date",
    "time",
    "tips",
    "parking",
    "firstname",
    "lastname",
    "phone",
    "address",
    "apt_no",
    "key_info",
    "note",
];

const HOUSE_CLEANING_KEYS: (keyof BookingInput)[] = [
    "no_full_bathrooms",
    "no_of_bedrooms"
];

const validators: Validator<BookingInput> = {
    service_type: emptyValidator,
    post_code: emptyValidator,
    frequency: emptyValidator,
    square_feet: emptyValidator,
    no_full_bathrooms: emptyValidator,
    no_of_bedrooms: emptyValidator,
    email: emailValidator,
    date: emptyValidator,
    time: emptyValidator,
    firstname: emptyValidator,
    lastname: emptyValidator,
    phone: emptyValidator,
    address: emptyValidator,
    apt_no: emptyValidator
}

const useBooking = () => {
    const { 
        step, 
        form, 
        formErrors, 
        openSelectDateModal,
    } = useAppSelector(state => state.bookingReducer);

    const { 
        setIsDateSelectModalOpen, 
        updateFormData, 
        setFormError, 
        setFormStep, 
        clearFormData, 
        clearFormError
    } = useBookingActions();

    const toggleDateModal = (val?: boolean) => {
        if (typeof val === "undefined") {
            setIsDateSelectModalOpen(!openSelectDateModal);
        } else {
            setIsDateSelectModalOpen(val);
        }
    }

    const amount = useMemo(() => {
        let price = 0;

        if (form.square_feet) {
            price += Number((form.square_feet * AMOUNT_PER_FEET).toFixed(2));
        }

        if (price < 90) {
            price = 90;
        }

        if (form.no_of_bedrooms && form.no_of_bedrooms > 1) {
            price += (form.no_of_bedrooms - 1) * AMOUNT_PER_EXTRA_BEDROOM;
        }

        if (form.no_full_bathrooms) {
            price += form.no_full_bathrooms * AMOUNT_PER_EXTRA_BATHROOM;
        }

        if (form.extra_services.length > 0) {
            form.extra_services.forEach(({service, quantity}) => {
                price += getExtraServicePrice(service, quantity);
            });
        }

        let addOn = 0;

        if (form.tips) {
            if (form.tips !== "Other") {
                addOn += getTipsPrice(form.tips, price);
            } else {
                addOn += price * ((form.otherTip || 0) / 100);
            }
        }

        if (form.parking) {
            if (form.parking !== "Other") {
                addOn += Number(form.parking);
            } else {
                addOn += Number(form.otherParking || 0);
            }
        }

        return Number((price + addOn).toFixed(2));
    }, [form]);

    const checkUserExistApi = useApi<{ data: { exists: boolean} }, string>(userService.check_user_exists);
    const submitCheckoutApi = useApi<{ data: { url: string } }, BookingInput>(checkoutService.submit_checkout);

    const handleSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
        e?.preventDefault();

        if (submitCheckoutApi.loading) {
            return;
        }

        submitCheckoutApi.reset();
        checkUserExistApi.reset();

        if (step === "details") {
            let exclude = STEP_TWO_KEYS;

            if (form.service_type === "post_construction_cleaning") {
                exclude = [ ...exclude, ...HOUSE_CLEANING_KEYS ];
            }

            const { valid, result } = validateForm<BookingInput>(validators, form, exclude);

            Object.keys(result).forEach((key) => setFormError(key as keyof BookingInput, result[key]));

            if (valid) {
                const response = await checkUserExistApi.request(form.email);

                if (response) {
                    updateFormData("user_exists", response.data.exists);
                    setFormStep("availability");
                }
            }
        } else if (step === "availability") {
            let exclude: (keyof BookingInput)[] = [];

            if (form.user_exists) {
                exclude = ["firstname", "lastname", "phone", "address", "apt_no"];
            }

            if (form.service_type === "post_construction_cleaning") {
                exclude = [ ...exclude, ...HOUSE_CLEANING_KEYS ];
            }

            const { valid, result } = validateForm<BookingInput>(validators, form, exclude);
        
            Object.keys(result).forEach((key) => setFormError(key as keyof BookingInput, result[key]));

            if (valid) {
                submitCheckoutApi.request(form).then((response) => {
                    if (response) {
                        window.open(response.data.url, "_blank");
                        setFormStep("details");
                        clearFormData();
                        clearFormError();
                    }
                });
            }
        }
    }

    return {
        bookingForm: form, 
        bookingFormErrors: formErrors,
        handleSubmit, 
        step, 
        loading: false, 
        amount, 
        openSelectDateModal,
        toggleDateModal,
        updateFormData,
        setFormStep,
        submiting: checkUserExistApi.loading || submitCheckoutApi.loading,
        submitError: checkUserExistApi.error || submitCheckoutApi.error
    };
}

export default useBooking;