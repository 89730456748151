import React from "react";
import "./styles.scss";
import { MdOutlineDashboard } from "react-icons/md";
import { GoGear } from "react-icons/go";
import { BsCalendar2Date } from "react-icons/bs";
import { BiLogOutCircle } from "react-icons/bi";

import { defaultAvatar } from "../../Assets/Images";
import { ROUTES } from "../../Router/routes";
import NavItem from "./NavItem";
import { useAppSelector } from "../../Hooks/useAppSelector";
import { useLogout } from "../../Hooks/useLogout";

interface Props {
    closeSideBar?: VoidFunction;
}

const Sidebar: React.FC<Props> = ({ closeSideBar }) => {
    const signOut = useLogout();

    const { user_data } = useAppSelector((state) => state.authReducer);

    const logout = () => {
        if (closeSideBar) closeSideBar();

        signOut();
    }

    return (
        <div className="bfs-sidebar">
            <div className="d-flex flex-column justify-content-center align-items-center py-4 logo-container">
                <img src={user_data?.avatar || defaultAvatar} alt="avatar" className="avatar" />

                <div className="name my-2">{user_data?.firstname} {user_data?.lastname}</div>
            </div>

            <div className="nav-items mt-4">
                <NavItem 
                    path={[ROUTES.index, ROUTES.dashboard.index]}
                    Icon={MdOutlineDashboard}
                    label={"Dashboard"}
                    closeSideBar={closeSideBar}
                />

                <NavItem 
                    path={ROUTES.dashboard.bookings}
                    Icon={BsCalendar2Date}
                    label={"Manage Booking"}
                    closeSideBar={closeSideBar}
                />

                <NavItem 
                    path={ROUTES.dashboard.settings}
                    Icon={GoGear}
                    label={"Settings"}
                    closeSideBar={closeSideBar}
                />

                <button className="button w-100 px-2" onClick={logout}>
                    <span className="w-100 text-start"><BiLogOutCircle size={28} /> Logout</span>
                </button>
            </div>
        </div>
    );
}

export default Sidebar;