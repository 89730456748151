import { Outlet } from "react-router-dom"
import Header from "./Components/Header"
import Footer from "./Components/Footer"
import React from "react"
import { useAppSelector } from "./Hooks/useAppSelector"
import GetAQuote from "./Components/GetAQuote"
import { useSharedActions } from "./Hooks/useReduxActions"

const PublicLayout: React.FC = () => {
    const { isGetQuoteOpen } = useAppSelector((state) => state.sharedReducer);

    const { closeGetQuote } = useSharedActions();

    return (
        <>
            <GetAQuote isOpen={isGetQuoteOpen} onClose={closeGetQuote} />

            <Header />

            <Outlet />

            <Footer />
        </>
    )
}

export default PublicLayout;