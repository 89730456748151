import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import PopModal from "../ModalLayout/ModalLayout";
import logo from "../../Assets/Images/logo.png";
import { useForm } from "../../Hooks/useForm";
import { GetQuoteInput } from "../../Interfaces/GetQuote";
import { emptyValidator } from "../../Utils/validators/emptyValidator";
import { nameValidator } from "../../Utils/validators/nameVaildator";
import InputField from "../InputField";
import { emailValidator } from "../../Utils/validators/emailValidator";
import SelectField from "../SelectField";
import publicService from "../../Services/PublicService";
import useApi from "../../Hooks/useApi";
import Loader from "../Loader";

interface GetAQuoteProps {
    isOpen: boolean;
	onClose: VoidFunction;
}

const submit = (data: GetQuoteInput) => publicService.submit_quote_request(data);

const GetAQuote: React.FC<GetAQuoteProps> = ({ isOpen, onClose }) => {
    const modalRef = useRef(null);

    const [success, setSuccess] = useState<boolean | null>(false);

    const getQuoteForm = useForm<GetQuoteInput>({
        desired_service: "",
        referred_by: "",
        email: "",
        firstname: "",
        lastname: "",
        phone: "",
        postalcode: "",
        square_feet: ""
    }, {
        desired_service: emptyValidator,
        referred_by: emptyValidator,
        email: emailValidator,
        firstname: nameValidator,
        lastname: nameValidator,
        postalcode: emptyValidator,
        phone: emptyValidator,
        square_feet: emptyValidator
    });

    const getQuoteApiRequest = useApi<{status: boolean}, GetQuoteInput>(submit);

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => getQuoteForm.onChange(e.target.name as keyof GetQuoteInput, e.target.value)

    const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();

        if (getQuoteApiRequest.loading) return;

        getQuoteForm.resetFormErrors();
        getQuoteApiRequest.reset();

        const valid = getQuoteForm.validate();

        console.log(getQuoteForm.formErrors);

        if (valid) {
            const res = await getQuoteApiRequest.request(getQuoteForm.form);

            if (res && res.status) {
                setSuccess(res.status);
            }
        }
    }

    const closeModal = () => {
        getQuoteForm.reset();
        getQuoteApiRequest.reset();
        setSuccess(null);
        onClose();
    }

    return (
        <PopModal modalToggler={isOpen} onClose={closeModal}>
            <div ref={modalRef} className="get-a-quote-modal">
                {isOpen && <div className="close" onClick={closeModal}>x</div>}

                <div className="container-fluid">
                    {!success && isOpen && <div className="row">
                        <form className="col-12 col-md-6 p-3 pt-4 pb-5" onSubmit={handleSubmit}>
                            <h1 className="mb-4">REQUEST A CLEANING ESTIMATE</h1>

                            <div className="row mb-3">
                                <div className="col-12 col-md-6">
                                    <InputField<GetQuoteInput> 
                                        name="firstname" 
                                        error={getQuoteForm.formErrors.firstname} 
                                        onChange={handleChange}
                                        value={getQuoteForm.form.firstname}
                                        placeholder="First Name"
                                    />
                                </div>

                                <div className="col-12 col-md-6">
                                    <InputField<GetQuoteInput> 
                                        name="lastname" 
                                        error={getQuoteForm.formErrors.lastname} 
                                        onChange={handleChange}
                                        value={getQuoteForm.form.lastname}
                                        placeholder="Last Name"
                                    />
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-12 col-md-6">
                                    <InputField<GetQuoteInput> 
                                        name="phone" 
                                        type="number"
                                        error={getQuoteForm.formErrors.phone} 
                                        onChange={handleChange}
                                        value={getQuoteForm.form.phone}
                                        placeholder="Phone"
                                    />
                                </div>

                                <div className="col-12 col-md-6">
                                    <InputField<GetQuoteInput> 
                                        name="email" 
                                        type="email"
                                        error={getQuoteForm.formErrors.email} 
                                        onChange={handleChange}
                                        value={getQuoteForm.form.email}
                                        placeholder="jdoe@bfs-cleaning.com"
                                    />
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-12 col-md-6">
                                    <InputField<GetQuoteInput> 
                                        name="postalcode"
                                        error={getQuoteForm.formErrors.postalcode} 
                                        onChange={handleChange}
                                        value={getQuoteForm.form.postalcode}
                                        placeholder="Postal Code"
                                    />
                                </div>

                                <div className="col-12 col-md-6">
                                    <InputField<GetQuoteInput> 
                                        name="square_feet"
                                        type="number"
                                        error={getQuoteForm.formErrors.square_feet} 
                                        onChange={handleChange}
                                        value={getQuoteForm.form.square_feet.toString()}
                                        placeholder="Square Feet"
                                    />
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-12 col-md-6">
                                    <SelectField<GetQuoteInput> 
                                        name="desired_service"
                                        error={getQuoteForm.formErrors.desired_service}
                                        value={getQuoteForm.form.desired_service}
                                        placeholder="Desired Service"
                                        onChange={(name, value) => getQuoteForm.onChange(name, value)}
                                        options={[
                                            { label: "Weekly Maid Service", value: "Weekly Maid Service" },
                                            { label: "Biweekly Maid Service", value: "Biweekly Maid Service" },
                                            { label: "Monthly Maid Service", value: "Monthly Maid Service" },
                                            { label: "One-Time Cleaning", value: "One-Time Cleaning" },
                                            { label: "Move In/Out Cleaning", value: "Move In/Out Cleaning" },
                                            { label: "Office Cleaning", value: "Office Cleaning" }
                                        ]}
                                    />
                                </div>

                                <div className="col-12 col-md-6">
                                    <SelectField<GetQuoteInput> 
                                        name="referred_by"
                                        error={getQuoteForm.formErrors.referred_by}
                                        value={getQuoteForm.form.referred_by}
                                        placeholder="How did you hear about us?"
                                        onChange={(name, value) => getQuoteForm.onChange(name, value)}
                                        options={[
                                            { label: "Angie's List", value: "Angie's List" },
                                            { label: "Better Business Bureau", value: "Better Business Bureau" },
                                            { label: "Email", value: "Email" },
                                            { label: "Facebook", value: "Facebook" },
                                            { label: "Google", value: "Google" },
                                            { label: "Home Advisor", value: "Home Advisor" },
                                            { label: "Money Mailer", value: "Money Mailer" },
                                            { label: "Next Door", value: "Next Door" },
                                            { label: "Referred by a Friend", value: "Referred by a Friend" },
                                            { label: "Yelp", value: "Yelp" },
                                            { label: "(Other)", value: "Other" }
                                        ]}
                                    />
                                </div>
                            </div>

                            <div className="row px-5">
                                <button type="submit" className="submit">
                                    {getQuoteApiRequest.loading ? <Loader /> : "Request a quote now"}
                                </button>
                            </div>
                        </form>

                        <div className="col-6 d-none d-md-block hero">
                            <div className="logo my-4 mx-auto">
                                <img src={logo} alt="logo" />
                            </div>

                            <h1>Sparkle up, Canada! We clean green</h1>
                        </div>
                    </div>}

                    {
                        success === true && <div className="success"> 
                            <div className="success-checkmark mb-2 mt-4">
                                <div className="check-icon">
                                <span className="icon-line line-tip"></span>
                                <span className="icon-line line-long"></span>
                                <div className="icon-circle"></div>
                                <div className="icon-fix"></div>
                                </div>
                            </div>

                            <h3>Thank You!</h3>

                            <p className="mb-5">
                                Someone on our team will reach out shortly with a quote.
<br/> 
Quote requests submitted after normal business hours will be completed the next business day.
                            </p>
                        </div>
                    }
                </div>
            </div>
        </PopModal>
    )
}

export default GetAQuote;