import React, { useEffect, useMemo, useRef, useState } from "react";

import { FiMenu } from "react-icons/fi";

import "./styles.scss";
import { logo } from "../../Assets/Images";
import { Link, useLocation } from "react-router-dom";
import { ROUTES } from "../../Router/routes";
import { CSSTransition } from "react-transition-group";
import Sidebar from "../Sidebar";

const AppHeader: React.FC = () => {
    const { pathname } = useLocation();

    const title = useMemo(() => {
        if (pathname === ROUTES.dashboard.index || pathname === ROUTES.index) {
            return "Dashboard";
        }

        if (pathname === ROUTES.dashboard.bookings) {
            return "Manage Booking";
        }

        if (pathname === ROUTES.dashboard.settings) {
            return "Settings";
        }

        return "";
    }, [pathname]);

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const openMobileMenu = () => {
		setIsMobileMenuOpen(true);
	};
	const closeMobileMenu = () => {
		setIsMobileMenuOpen(false);
	};
    const mobileMenuRef = useRef<any>();

    useEffect(() => {
        if (isMobileMenuOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [isMobileMenuOpen]);

    return (
        <>

            <CSSTransition
                nodeRef={mobileMenuRef}
                in={isMobileMenuOpen}
                classNames={'mobile-menu-nav'}
                unmountOnExit
                timeout={500}
            >
                <div
                    ref={mobileMenuRef}
                    className='mobile-menu-nav'
                    onClick={closeMobileMenu}
                >
                    <div className={`mobile-menu-nav__sidebar ${isMobileMenuOpen ? 'slide-in-left' : 'slide-out-left'}`} onClick={(e) => e.stopPropagation()}>
                        <Sidebar closeSideBar={closeMobileMenu} />
                    </div>
                </div>
            </CSSTransition>

            <header className="bfs-app-header w-100 px-4 py-2 d-flex flex-row justify-content-between align-items-center">
                <div className="d-flex flex-row align-items-center">
                    <div className="hamburger me-4 d-block d-md-none" onClick={openMobileMenu}>
                        <FiMenu size={"30px"} />
                    </div>

                    <h4>{title}</h4>
                </div>
                
                <Link to={ROUTES.dashboard.index} className="logo_container">
                    <img src={logo} alt="logo" className="logo" />
                </Link>
            </header>
        </>
    )
}

export default AppHeader;