import { useNavigate } from "react-router-dom"
import { useAuthActions } from "./useReduxActions";
import { ROUTES } from "../Router/routes";
import { useForm } from "./useForm";
import { LoginInput } from "../Interfaces/Auth";
import { emailValidator } from "../Utils/validators/emailValidator";
import { passwordValidator } from "../Utils/validators/passwordValidator";
import useApi from "./useApi";
import { BfsToken, User } from "../Interfaces/User";
import authService from "../Services/AuthService";

export const useAuth = () => {
    const navigate = useNavigate();
    const { signIn } = useAuthActions();

    const loginForm = useForm<LoginInput>({
        email: "",
        password: ""
    }, {
        email: emailValidator,
        password: passwordValidator
    });

    const loginApiRequest = useApi<{tokens: BfsToken, user: User}, LoginInput>(authService.login);

    const handleLogin = async () => {
        loginForm.resetFormErrors();
        loginApiRequest.reset();

        const valid = loginForm.validate();

        if (valid && !loginApiRequest.loading) {
            // call login api
            const response = await loginApiRequest.request(loginForm.form);

            if (response) {
                signIn(response);
                navigate(ROUTES.index);
            }
        }
    }

    return { loginForm, handleLogin, loginLoading: loginApiRequest.loading, loginError: loginApiRequest.error };
}