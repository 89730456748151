import { Validator } from "../Interfaces/Validator";

export const validateForm = <T extends object>(validators: Validator<T>, form: T, except: (keyof T)[] = []) => {
    const result: Record<string, string | null> = {};

    Object.keys(validators).forEach((key) => {
        const validator = validators[key as keyof T];

        if (validator && !except.includes(key as keyof T)) {
            const error = validator(form[key as keyof T]);

            result[key] = error;
        }
    });

    return { result, valid: Object.keys(result).filter( key => Boolean(result[key]) ).length === 0 };
};