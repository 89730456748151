import "./styles.scss";
import { Icon } from '@iconify/react';

const Contact: React.FC = () => {

    return (
        <section id="contact" className="cl-contact p-3 p-md-5 d-flex flex-row align-items-stretch">
            <div>
                <iframe 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2881.938390406613!2d-79.3172286238959!3d43.75337614594601!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4d27313d77c9d%3A0x289c9ac3e275b426!2s275%20Cassandra%20Blvd%2C%20North%20York%2C%20ON%20M3A%201V7%2C%20Canada!5e0!3m2!1sen!2sng!4v1685624485254!5m2!1sen!2sng" 
                    width={"100%"} 
                    height={"100%"} 
                    style={{border: 0, minHeight: 450}} 
                    allowFullScreen 
                    loading="lazy" 
                    referrerPolicy="no-referrer-when-downgrade">
                </iframe>
            </div>

            <div className="info">
                <h4>Your Go-To Cleaning Experts.</h4>

                <p>Service Area: GTA: East York, Etobicoke, North York, Scarborough and surrounding areas.</p>

                <div>
                    <Icon icon="mdi:address-marker" />

                    <div className="d-inline-block ms-2">
                        <span>Address</span>
                        <span>275 cassandra bvld</span>
                    </div>
                </div>

                <div>
                    <Icon icon="gg:phone" />

                    <div className="d-inline-block ms-2">
                        <span>Phone</span>
                        <span>+14376735064</span>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact;