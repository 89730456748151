import axios from 'axios';
import { API_URL } from '../Config/env';

class UserService {
	check_user_exists = (data: string) => {
		return axios.get(`${API_URL}/users/exists/${data}`);
	};
}

const userService = new UserService();
export default userService;
