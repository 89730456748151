import { BfsTips } from "../Interfaces/Booking";

export const getTipsPrice = (tip: BfsTips, amount: number) => {
    switch (tip) {
        case "0%":
            return 0;

        case "10%":
            return amount * (10/100);
            
        case "15%":
            return amount * (15/100);
        
        case "20%":
            return amount * (20/100);
    
        default:
            return 0;
    }
}