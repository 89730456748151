import React from "react";
import { Link } from "react-router-dom";

import "./styles.scss";

import { ROUTES } from "../../Router/routes";

const Hero: React.FC = () => {

    return (
        <section className="cl-hero-section">
            <div className="bg-blur"></div>
            <div className="cl-hero-section__info">
                <h1>
                    <span>Sparkle and Shine:</span> Your Home Deserves the Best Cleaning Service.
                </h1>

                <p>Experience the joy of a spotless space, one room at a time.</p>

                <Link to={ROUTES.book.index}>
                    <button className="button button-round book-now">
                        Book Now
                    </button>
                </Link>
            </div>
        </section>
    )
}

export default Hero