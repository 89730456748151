import { Dispatch } from "react";
import { BookingActionTypes } from "./action-types";
import { ActionConstants } from "./action-constants";
import { Booking, BookingFormStep, BookingInput } from "../../Interfaces/Booking";

export const setIsDateSelectModalOpen = (val: boolean) => (dispatch: Dispatch<BookingActionTypes>) => {
    if (val) {
        dispatch({ type: ActionConstants.OPEN_DATE_SELECT });
    } else {
        dispatch({ type: ActionConstants.CLOSE_DATE_SELECT });
    }
};

export const updateFormData = (
    key: keyof BookingInput,
    value: BookingInput[keyof BookingInput]
) => (dispatch: Dispatch<BookingActionTypes>) => {
    dispatch({ type: ActionConstants.SET_FORM_DATA, payload: {key, value} });
}

export const clearFormData = () => (dispatch: Dispatch<BookingActionTypes>) => {
    dispatch({ type: ActionConstants.CLEAR_FORM_DATA });
}

export const setFormStep = (data: BookingFormStep) => (dispatch: Dispatch<BookingActionTypes>) => {
    dispatch({ type: ActionConstants.SET_FORM_STEP, payload: data });
}

export const setFormError = (key: keyof BookingInput, value: string | null) => (dispatch: Dispatch<BookingActionTypes>) => {
    dispatch({
        type: ActionConstants.SET_FORM_ERROR,
        payload: {
            key, 
            value
        }
    })
}

export const clearFormError = () => (dispatch: Dispatch<BookingActionTypes>) => {
    dispatch({
        type: ActionConstants.CLEAR_FORM_ERROR
    })
}

export const clearState = () => (dispatch: Dispatch<BookingActionTypes>) => {
	dispatch({type: ActionConstants.CLEAR});
}

export const setList = (data: {bookings: Booking[], totalCount: number; }) => (dispatch: Dispatch<BookingActionTypes>) => {
    dispatch({
        type: ActionConstants.SET_LIST,
        payload: data
    })
}