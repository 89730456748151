import { useEffect, useState } from "react";
import useApi from "./useApi";
import { Booking } from "../Interfaces/Booking";
import { BfsToken } from "../Interfaces/User";
import bookingService from "../Services/BookingService";
import { useAppSelector } from "./useAppSelector";
import { useBookingActions } from "./useReduxActions";

export const useManageBooking = () => {
    const [perPage, setPerPage] = useState(10);
    const {
        data,
    } = useAppSelector(state => state.bookingReducer);

    const { bookings, totalCount } = data;

    const { setList } = useBookingActions();

    const { tokens } = useAppSelector((state) => state.authReducer);
    const fetchBookingsApi = useApi<{data: Booking[], totalPage: number }, {tokens: BfsToken, page: number, limit: number}>(bookingService.get);

    const fetchBookings = (page: number = 1, limit?: number) => {
        if (tokens) {
            fetchBookingsApi.request({tokens, page, limit: limit || perPage})
                .then((response) => {
                    if (response)
                        setList({
                            bookings: response.data,
                            totalCount: response.totalPage
                        });
                });
        }
    }
    const handlePerRowsChange = (newPerPage: number, page: number) => {
        fetchBookings(page, newPerPage);
        setPerPage(newPerPage);
	};

    const handlePageChange = (page: number) => fetchBookings(page, perPage);

    useEffect(() => {
        fetchBookings();
    }, [tokens]);

    return {
        totalCount,
        bookings,
        fetching: fetchBookingsApi.loading,
        handlePerRowsChange,
        handlePageChange
    }
}