import { useLocation } from "react-router-dom"

export const useSidebar = () => {
    const { pathname } = useLocation();
 
    const getClassName = (path: string | string[]) => {
        if (typeof path === "string" && pathname === path) {
            return 'active';
        } else if (Array.isArray(path) && path.includes(pathname)) {
            return 'active';
        }

        return '';
    }
    


    return {
        getClassName,
    }
}