import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { 
	sharedActions, 
	bookingActions, 
	authActions,
	reviewActions
} from '../Redux/allActions';

export const useSharedActions = () => {
	const dispatch = useDispatch();

	return bindActionCreators(Object.assign({}, sharedActions), dispatch);
};

export const useBookingActions = () => {
	const dispatch = useDispatch();

	return bindActionCreators(Object.assign({}, bookingActions), dispatch);
}

export const useAuthActions = () => {
	const dispatch = useDispatch();

	return bindActionCreators(Object.assign({}, authActions), dispatch);
}

export const useReviewActions = () => {
	const dispatch = useDispatch();

	return bindActionCreators(Object.assign({}, reviewActions), dispatch);
}