import "./styles.scss";

interface Props {
  label?: string;
  checked: boolean;
  onChange: (val: boolean) => void;
}

const CheckboxRound: React.FC<Props> = ({ label, checked, onChange }) => {
  return (
    <div className="ml-checkbox-round-container">
      <label className="checkbox-round-wrapper">
        <input
          hidden
          name="ml-checkbox"
          type="checkbox"
          className="ml-input-checkbox"
          checked={checked}
          onChange={() => onChange(checked ? false : true)}
        />
        <span className="checkbox-round"></span>
      </label>

      {label && <div className="checkbox-round-label">{label}</div>}
    </div>
  );
};

export default CheckboxRound;
