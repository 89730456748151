import './App.scss';
import "animate.css";
import AppRouter from './Router/Router';

function App() {
  return (
    <div className="cl-App">
      <AppRouter />
    </div>
  );
}

export default App;
