import axios from "axios";
import { API_URL } from "../Config/env";
import { BfsToken } from "../Interfaces/User";
import { getAuthHeader } from "../Utils";

class BookingService {
    get = async ( { 
        tokens, 
        page,
        limit
    } :{tokens: BfsToken, page: number, limit: number}) => {
        return await axios.get(`${API_URL}/bookings?page=${page}&limit=${limit}`, {
            headers: getAuthHeader(tokens)
        });
    };
}

const bookingService = new BookingService();
export default bookingService;