export const formatDDMMYY = (date_string: string | Date) => {
  const date = new Date(date_string);

  if (!date) return;

  const month =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};