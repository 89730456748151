import { useNavigate } from "react-router-dom";
import { useAuthActions, useBookingActions, useSharedActions } from "./useReduxActions"
import { ROUTES } from "../Router/routes";

export const useLogout = () => {
    const navigate = useNavigate();
    const { signOut } = useAuthActions();
    const shared = useSharedActions();
    const booking = useBookingActions();

    const logout = () => {
        shared.clearState();
        booking.clearState();

        signOut();

        navigate(ROUTES.index);
    }

    return logout;
}