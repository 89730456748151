import React from "react";
import "./styles.scss";
import aboutImg from "../../Assets/Images/aboutus.png";

const AboutUs: React.FC = () => {

    return (
        <section id="about" className="cl-about-us">
            <div>
                <h4>About Us</h4>

                <h1><span>BF</span>S Cleaning Services: Where Cleanliness Meets Perfection.</h1>

                <p>
                    Discover BFS Cleaning Service, the go-to solution for all your cleaning requirements. We are a team of dedicated professionals committed to providing impeccable cleaning services to both residential and commercial clients. Our mission is to establish strong relationships with our clients, treating them like family while ensuring their environments are clean and healthy. With our highly skilled cleaners and unwavering focus on customer satisfaction, we strive to exceed expectations in every job we undertake. Whether you need regular home cleaning, office cleaning, or specialized cleaning services, rest assured, we have the expertise and resources to cater to your unique needs. Trust BFS Cleaning Service to make your space spotless and fresh.
                </p>

                <a href="tel:+14376735064" className="button button-round-rect call-for-quote">
                    Call for a Quote
                </a>
            </div>

            <div>
                <img className="about" src={aboutImg} alt="" />
            </div>
        </section>
    )
}

export default AboutUs;