import React from "react";
import { Outlet } from "react-router-dom";

import Sidebar from "./Components/Sidebar";
import AppHeader from "./Components/AppHeader";

const AppLayout: React.FC = () => {

    return (
        <div className="bfs-app-layout d-flex flex-row w-100">
            <Sidebar />

            <div className="main-content">
                <AppHeader />
                
                <Outlet />
            </div>
        </div>
    )
}

export default AppLayout;