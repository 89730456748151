import { Dispatch } from 'react';
import { ActionConstants } from './action-constants';
import { AuthActionTypes } from './action-types';
import { Review } from '../../Interfaces/Review';

export const setLatest =
	(data: Review[]) =>
	(dispatch: Dispatch<AuthActionTypes>) => {
		dispatch({ type: ActionConstants.SET_LATEST, payload: data });
	};
