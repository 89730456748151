import { Dispatch } from 'react';
import { ActionConstants } from './action-constants';
import { SharedActionTypes } from './action-types';

export const openGetQuote = () => (dispatch: Dispatch<SharedActionTypes>) => {
	dispatch({ type: ActionConstants.OPEN_GET_QUOTE });
};

export const closeGetQuote = () => (dispatch: Dispatch<SharedActionTypes>) => {
	dispatch({ type: ActionConstants.CLOSE_GET_QUOTE });
};

export const clearState = () => (dispatch: Dispatch<SharedActionTypes>) => {
	dispatch({type: ActionConstants.CLEAR});
}