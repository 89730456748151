import { Icon } from "@iconify/react";
import "./styles.scss";
import { Link } from "react-router-dom"

const Footer: React.FC = () => {

    return (
        <div className="cl-footer">
            <div className="container p-5">
                <div className="row gx-5 gy-5 gy-md-0">
                    <div className="col">
                        <h5>Cleaning Services</h5>

                        <ul>
                            <li><Link to={"#services"} >Home Cleaning</Link></li>
                            <li><Link to={"#services"} >Apartment Cleaning</Link></li>
                            <li><Link to={"#services"} >Post-construction Cleaning</Link></li>
                            <li><Link to={"#services"} >Deep Cleaning</Link></li>
                            <li><Link to={"#services"} >Move Cleaning</Link></li>
                            <li><Link to={"#services"} >Condo Cleaning</Link></li>
                        </ul>
                    </div>
                    
                    <div className="col">
                        <h5>Service Locations</h5>

                        <ul style={{gap: 0}}>
                            <li><Link to={"#"} >East York</Link></li>
                            <li><Link to={"#"} >Etobicoke</Link></li>
                            <li><Link to={"#"} >North York</Link></li>
                            <li><Link to={"#"} >Scarborough</Link></li>
                            <li><Link to={"#"} >Toronto</Link></li>
                            <li><Link to={"#"} >Barrie</Link></li>
                            <li><Link to={"#"} >Milton</Link></li>
                            <li><Link to={"#"} >Brampton</Link></li>
                        </ul>
                    </div>

                    <div className="col">
                        <h5>Contact Information</h5>

                        <ul>
                            <li><Link to={"#"} ><Icon icon="mdi:address-marker" /> 275 cassandra bvld</Link></li>
                            <li><Link to={"#"} ><Icon icon="gg:phone" /> +14376735064</Link></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="container-fluid d-flex align-items-center justify-content-between px-5 py-4 copyright-area">
                <div className="mx-0 mx-md-5">© 2023 BFS Cleaning Services All Rights Reserved.</div>

                <div className="socials d-flex flex-row mx-0 mx-md-5">
                    <Link to="#"><Icon icon="mdi:youtube" /></Link>

                    <Link to="#"><Icon icon="bxl:facebook" /></Link>

                    <Link to="#"><Icon icon="uil:twitter" /></Link>

                    <Link to="#"><Icon icon="iconoir:instagram" /></Link>
                </div>
            </div>
        </div>
    )
}

export default Footer;