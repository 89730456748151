import axios from "axios";
import { API_URL } from "../Config/env";

class ReviewService {
    get_latest = async () => {
        return await axios.get(`${API_URL}/reviews/latest`);
    };
}

const reviewService = new ReviewService();
export default reviewService;