import produce from 'immer';
import { ActionConstants } from './action-constants';
import { AuthActionTypes } from './action-types';
import { Review } from '../../Interfaces/Review';

interface initialStateInterface {
	latest_reviews: Review[];
}

const initialState: initialStateInterface = {
	latest_reviews: []
};

export const reviewReducer = (
	state: initialStateInterface = initialState,
	Action: AuthActionTypes
): initialStateInterface => {
	return produce(state, (review) => {
		switch (Action.type) {
			case ActionConstants.SET_LATEST:
				review.latest_reviews = Action.payload;
				return review;

			default:
				return review;
		}
	});
};
