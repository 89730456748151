import React, { useEffect, useState } from "react"
import PaymentResultModal from "../Components/PaymentResultModal";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../Router/routes";

interface Props {

}

export const BookingResult: React.FC<Props> = () => {
    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState<"success" | "canceled">();
    const navigate = useNavigate();

    useEffect(() => {
        const search = new URLSearchParams(window.location.search);

        const success = search.get("success");
        const canceled = search.get("canceled");

        setOpen(Boolean(success || canceled));

        if (success) {
            setStatus('success');
        } else if (canceled) {
            setStatus('canceled');
        }
    }, []);

    const handleOk = () => {
        setOpen(false);

        if (status === "success") {
            navigate(ROUTES.login);
        } else if (status === "canceled") {
            navigate(ROUTES.book.index);
        }

        setStatus(undefined);
    }

    return (
        <>
            <PaymentResultModal 
                isOpen={open}
                handleOk={handleOk}
                status={status}
            />
        </>
    );
}