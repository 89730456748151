import { useState } from 'react';
import { Validator } from '../Interfaces/Validator';
import { getDefaultFormErrors } from '../Utils';

export type FormError<T extends object> = {
	[Prop in keyof T]: string | null;
};

export const useForm = <T extends object>(
	initialState: T,
	validators: Validator<T>
) => {
	const [form, setForm] = useState<T>(initialState);

	const [formErrors, setFormErrors] = useState<FormError<T>>(
		getDefaultFormErrors<T>(initialState)
	);

	const resetFormErrors = (key?: keyof T) => {
		if (!key) {
			setFormErrors(getDefaultFormErrors<T>(initialState));
		} else {
			setFormErrors((prev) => ({ ...prev, key: null }));
		}
	};

	const onChange = (name: keyof T, value: T[keyof T]) => {
		setForm((prev) => ({ ...prev, [name]: value }));

		const validator = validators[name as keyof T];

		const error = validator ? validator(value) : null;

		setFormErrors((prev) => ({ ...prev, [name as keyof T]: error }));
	};

	const validate = (): boolean => {
		let valid = true;

		Object.keys(validators).forEach((key) => {
			const validator = validators[key as keyof T];

			if (validator) {
				const error = validator(form[key as keyof T]);

				if (error) valid = false;
			}
		});

		return valid;
	};

	const reset = () => {
		setForm(initialState);
		setFormErrors(getDefaultFormErrors<T>(initialState));
	};

	return { form, onChange, reset, resetFormErrors, validate, formErrors };
};
