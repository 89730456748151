import { Dispatch } from 'react';
import { ActionConstants } from './action-constants';
import { AuthActionTypes } from './action-types';
import { BfsToken, User } from '../../Interfaces/User';

export const signIn =
	(data: { user: User; tokens: BfsToken }) =>
	(dispatch: Dispatch<AuthActionTypes>) => {
		dispatch({ type: ActionConstants.LOGIN, payload: data });
	};

export const signOut = () => (dispatch: Dispatch<AuthActionTypes>) => {
	dispatch({ type: ActionConstants.LOGOUT });
};

export const updateUserData =
	(data: User) => (dispatch: Dispatch<AuthActionTypes>) => {
		dispatch({ type: ActionConstants.UPDATE_USER_DATA, payload: data });
	};
