import axios from 'axios';
import { API_URL } from '../Config/env';
import { BookingInput } from '../Interfaces/Booking';

class CheckoutService {
	submit_checkout = (data: BookingInput) => {
		return axios.post(`${API_URL}/checkout`, data);
	};
}

const checkoutService = new CheckoutService();
export default checkoutService;
