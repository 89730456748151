import produce from 'immer';
import { ActionConstants } from './action-constants';
import { SharedActionTypes } from './action-types';

interface initialStateInterface {
	isGetQuoteOpen: boolean;
}

const initialState = {
	isGetQuoteOpen: false
};

export const sharedReducer = (
	state: initialStateInterface = initialState,
	Action: SharedActionTypes
): initialStateInterface => {
	return produce(state, (shared) => {
		switch (Action.type) {
			case ActionConstants.OPEN_GET_QUOTE:
				shared.isGetQuoteOpen = true;
				return shared;

			case ActionConstants.CLOSE_GET_QUOTE:
				shared.isGetQuoteOpen = false;
				return shared;

			case ActionConstants.CLEAR:
				shared = { ...shared, ...initialState};
				return shared;

			default:
				return shared;
		}
	});
};
