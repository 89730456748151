import React, { useMemo } from "react";
import "./styles.scss";
import { BfsServices } from "../../../Interfaces/Booking";

interface Props {
    title: string;
    icon: JSX.Element;
    selected: boolean;
    single?: boolean;
    onSelect: VoidFunction;
    service?: BfsServices;
    extra_services?: {service: BfsServices, quantity: number}[];
    setQuantity?: (val: number) => void;
}

const ExtraService: React.FC<Props> = (props) => {
    const { title, single = true, onSelect, icon, selected, service, extra_services = [], setQuantity } = props;

    const handleQuantityAction = (mode: '-' | '+') => (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        e.stopPropagation();

        if (!quantity || !setQuantity) return;
        
        let newQuantity = mode === "+" ? quantity + 1 : quantity - 1;

        if (newQuantity < 1) newQuantity = 1;

        setQuantity(newQuantity);
    }

    const quantity = useMemo(() => {
        if (!service) return null;

        return extra_services.filter((e) => e.service === service)[0]?.quantity;
    }, [extra_services]);

    return (
        <div className={`bfs-extra-service ${selected ? 'selected' : undefined}`}>
            <div className="card p-2 d-flex align-items-center justify-content-center" onClick={onSelect}>
                <div className="overlay card">
                    {!single && selected && (
                        <>
                            <span onClick={handleQuantityAction('-')}>-</span>
                            <span>{quantity}</span>
                            <span onClick={handleQuantityAction('+')}>+</span>
                        </>
                    )}
                </div>

                {icon}
            </div>
            <span className="title">{title}</span>
        </div>
    );
}

export default ExtraService;