import { AxiosResponse } from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../Router/routes';
import { useLogout } from './useLogout';

export default <T, K>(apiFunc: (arg: K) => Promise<AxiosResponse>) => {
	const navigate = useNavigate();
	const [data, setData] = useState<T | null>(null);
	const [error, setError] = useState<string>('');
	const [loading, setLoading] = useState(false);
	const logout = useLogout();

	const request = async (arg?: K): Promise<T | null> => {
		setLoading(true);

		try {
			const result = await apiFunc(arg!);
			setData(result.data);
			return result.data;
		} catch (err: any) {
			if (err.response?.status >= 401 && err.response?.status <= 403) {
				logout();
				navigate(ROUTES.index);
			}

			const error = err.response?.data || {};

			if (!error || !error.message) {
				if (err.message) {
					error.message = err.message;
				} else {
					error.message = 'Something Went Wrong!';
				}
			}
			setError(error.message);
			return null;
		} finally {
			setLoading(false);
		}
	};

	const reset = () => {
		setLoading(false);
		setError('');
		setData(null);
	};

	return {
		data,
		error,
		loading,
		request,
		reset,
	};
};
