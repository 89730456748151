import React from "react";
import "./styles.scss";
import defaultImg from "../../Assets/Images/default.jpeg";
import { useReviews } from "../../Hooks/useReviews";

const Reviews: React.FC = () => {
    const { latest_reviews } = useReviews();

    return (
        <section id="reviews" className="cl-reviews">
            <h4>Customers Share Their Delightful Cleaning Experiences!</h4>

            <div className="reviews">
                <div className="d-flex flex-row justify-content-center align-items-stretch w-100 my-5">
                    <div className="ribbon d-none d-md-block"></div>

                    {latest_reviews.map((item) => (
                        <div key={item.id+'-review'} className="position-relative review">
                            <div className="ribbon"></div>

                            <div className="card review-card pb-4">
                                <div className="card-body review-content">
                                    <img src={defaultImg} alt="user-avatar" className="reviewer-img mb-4" />

                                    <h3 className="reviewer-name">{item.name}</h3>

                                    <p className="review-text">{item.text}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                    
                </div>
            </div>

            <button className="text-button leave-review mx-auto my-5">
                Leave a review
            </button>
        </section>
    )
}

export default Reviews;