export const ROUTES = {
    index: "/",
    login: "/login",
    forgot_password: "/forgot-password",
    dashboard: {
        index: "/dashboard",
        bookings: "/dashboard/manage-booking",
        settings: "/dashboard/settings"
    },
    book: {
        index: "/book",
        result: "/book/result"
    }
}