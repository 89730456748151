import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import PublicLayout from "../PublicLayout";
import { BookOnline, BookingResult, Home, ManageBooking, Settings, Dashboard, Login } from "../Pages";
import { ROUTES } from "./routes";
import { useAppSelector } from "../Hooks/useAppSelector";
import AppLayout from "../AppLayout";

const AppRouter: React.FC = () => {
    const { tokens } = useAppSelector((state) => state.authReducer);

    return (
        <BrowserRouter>
            <Routes>
                {!tokens && (
                    <Route path="/" element={<PublicLayout />}>
                        <Route index element={<Home />} />
                        <Route path={ROUTES.login} element={<Login />} />
                        <Route path={ROUTES.book.index} element={<BookOnline />} />
                        <Route path={ROUTES.book.result} element={<BookingResult />} />
                    </Route>
                )}

                {tokens && (
                    <Route path="/" element={<AppLayout />} >
                        <Route index element={<Dashboard />} />
                        <Route path={ROUTES.dashboard.index} element={<Dashboard />} />
                        <Route path={ROUTES.dashboard.bookings} element={<ManageBooking />} />
                        <Route path={ROUTES.book.index} element={<BookOnline />} />
                        <Route path={ROUTES.book.result} element={<BookingResult />} />
                        <Route path={ROUTES.dashboard.settings} element={<Settings />} /> 
                    </Route>
                )}
            </Routes>
        </BrowserRouter>
    )
}

export default AppRouter;