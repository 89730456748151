import React from "react";
import "./styles.scss";

interface Prop extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    title: string;
    variant?: "secondary" | "primary"; 
    loading?: boolean;
}

const AppButton: React.FC<Prop> = (props) => {
    const {
        title,
        variant = "secondary",
        loading,
        ...rest
    } = props;

    return (
        <button className={`bfs-app-button ${variant} p-2`} disabled={loading} {...rest}>
            {
                loading ? (
                    <div className="spinner-border spinner-border-sm mx-3" role="status">
                    </div>
                ) : (
                    title
                )
            }
        </button>
    )
}

export default AppButton;