import "./styles.scss";

interface InputFieldProps<T> {
	type?: 'text' | 'password' | 'number' | 'email' | 'date';
	label?: string;
	value: string;
	placeholder?: string;
	onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
	name: keyof T;
	error: string | null;
	min?: number;
	max?: number;
	maxLength?: number;
	required?: boolean;
	prepend?: string | JSX.Element;
	multiline?: boolean;
}

const InputField = <T,>(props: InputFieldProps<T>) => {
	const {
		type = 'text',
		value,
		onChange,
		name,
		error,
		placeholder,
		label,
		maxLength,
		min,
		max,
		required,
		prepend,
		multiline 
	} = props;

	const handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
		if (type === 'number' && maxLength && value.length === maxLength) {
			return;
		}

		onChange(e);
	};

	return (
		<div className="bfs-input">
			{label && <label htmlFor={name.toString() + '-id'} className='form-label'>
				{label}
			</label>}

			{!multiline ? (
					<div className="input-group">
						<input
							type={type}
							id={name.toString() + '-id'}
							className='form-control'
							name={name.toString()}
							value={value}
							onChange={handleChange}
							placeholder={placeholder}
							min={min}
							max={max}
							maxLength={maxLength}
							required={required}
						/>

						{prepend && (
							<div className='input-group-prepend'>
								<span className='input-group-text h-100'>{prepend}</span>
							</div>
						)}
					</div>
				) : (
					<textarea 
						name={name.toString()} 
						id={name.toString() + '-id'} 
						rows={5}
						value={value}
						onChange={handleChange}
						required={required}
						className="form-control"
						placeholder={placeholder}
					/>
				)
			}
			{error && (
				<div className='input-error mt-1'>
					{(
						name.toString().substring(0, 1).toUpperCase() +
						name.toString().substring(1)
					).replace('_', ' ') +
						' ' +
						error}
				</div>
			)}
		</div>
	);
};

export default InputField;
