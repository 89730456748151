import React from "react";
import "./styles.scss";
import logo from "../../Assets/Images/logo.png";
import { useSharedActions } from "../../Hooks/useReduxActions";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../Router/routes";

const Header: React.FC = () => {
    const { openGetQuote } = useSharedActions();
    const navigate = useNavigate();

    const goHome = () => navigate(ROUTES.index);

    return (
        <header className="cl-header">
            <nav>
                <div className="cl-header__logo" onClick={goHome}>
                    <img src={logo} alt="logo" />
                </div>

                <div className="w-100 d-flex flex-row justify-content-between flex-wrap">
                    <ul>
                        <li onClick={goHome}><a href="#home">Home</a></li>
                        <li onClick={goHome}><a href="#about">About</a></li>
                        <li onClick={goHome}><a href="#services">Services</a></li>
                        <li onClick={goHome}><a href="#reviews">Reviews</a></li>
                        <li onClick={goHome}><a href="#contact">Contact</a></li>
                    </ul>

                    <div className="cl-header__actions">
                        <Link to={ROUTES.book.index}>
                            <button className="button button-round book-now">
                                Book Now
                            </button>
                         </Link>

                        <button className="button button-round get-quote" onClick={openGetQuote}>
                            Get a Quote
                        </button>
                       
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Header;