import { useEffect } from "react";
import { Review } from "../Interfaces/Review"
import reviewService from "../Services/ReviewService"
import useApi from "./useApi"
import { useReviewActions } from "./useReduxActions";
import { useAppSelector } from "./useAppSelector";

export const useReviews = () => {
    const { latest_reviews } = useAppSelector((state) => state.reviewReducer);

    const fetchLatestReviewsApi = useApi<Review[], never>(reviewService.get_latest);

    const { setLatest } = useReviewActions();

    useEffect(() => {
        fetchLatestReviewsApi.request().then(response => {
            if (response) {
                setLatest(response);
            }
        })
    }, []);

    return { 
        latest_reviews
    };
}