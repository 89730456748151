import PopModal from "../ModalLayout/ModalLayout";
import "./styles.scss";

import React from "react";

interface Props {
    isOpen: boolean;
    handleOk: VoidFunction;
    status?: "success" | "canceled"
}

const PaymentResultModal: React.FC<Props> = (props) => {
    const { isOpen, status, handleOk } = props;

    const getTitle = () => {
        if (status === "success") {
            return "Payment Submitted Successfully!";
        }

        if (status === "canceled") {
            return "Payment Canceled";
        }

        return "";
    }

    const getText = () => {
        if (status === "success") {
            return "Please login to manage your appointments";
        }

        return "";
    }

    return (
        <PopModal modalToggler={isOpen && typeof status !== "undefined"} onClose={() => {}}>
            <div className="bfs-payment-result-modal p-4">
                <div className='sweet-alert'>
					{status === "success" &&<div className='sa-icon sa-success animate'>
						<span className='sa-line sa-tip animateSuccessTip'></span>
						<span className='sa-line sa-long animateSuccessLong'></span>
						<div className='sa-placeholder'></div>
						<div className='sa-fix'></div>
					</div>}

                    {status === "canceled" && <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                        <circle className="path circle" fill="none" stroke="#D06079" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                        <line className="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3"/>
                        <line className="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2"/>
                    </svg>}

					<h2>{getTitle()}</h2>

					<p style={{ display: 'block' }}>{getText()}</p>

					<div className='sa-button-container mt-4'>
						<div className='sa-confirm-button-container'>
                            <button className='button button-round-rect mx-auto book-now' type="button" onClick={handleOk}>
                                { status === "success" ? "Continue" : "Close" }
                            </button>
						</div>
					</div>
				</div>
            </div>
        </PopModal>
    )
}

export default PaymentResultModal;