import { BfsServices } from "../Interfaces/Booking";

export const getExtraServicePrice = (service: BfsServices, quantity: number) => {
    switch (service) {
        case "deep_cleaning":
            return 60 * quantity;

        case "inside_fridge":
            return 35 * quantity;

        case "move_clean": 
            return 50 * quantity;

        case "finished_basement":
            return 57 * quantity;

        case "inside_oven":
            return 25 * quantity;

        case "inside_bathroom_cabinets":
        case "inside_kitched_cabinets":
            return 10 * quantity;

        case "dishes":
            return 5 * quantity;

        case "change_linens":
            return 10 * quantity;
    
        default:
            return 0;
    }
}