import React from "react";

import "./styles.scss"
import { StepOne, StepTwo } from "./Steps";
import useBooking from "../../Hooks/useBooking";

const BookOnlineForm: React.FC = () => {
    const { 
        handleSubmit,
        step,
        submiting
    } = useBooking();

    return (
        <form className="container bfs-book-online-form" onSubmit={handleSubmit}>
            {
                step === "details" && (
                    <StepOne />
                )
            }

            {
                step === "availability" && (
                    <StepTwo />
                )
            }

            <button className="button button-round-rect" disabled={submiting}>
                {
                    step === "details" ? "Next" : "Book Now"
                }
            </button>
        </form>
    )
}

export default BookOnlineForm;