export const formatTime = (time: number | string) => {
    time = Number(time);

    const timeOfDay = time >= 12 ? "pm" : "am";

    if (time === 0) {
        time = 12;
    }

    if (time > 12) {
        time = time - 12;
    }

    return `${time} ${timeOfDay}`;
}