import './styles.scss';

const Loader: React.FC = () => {
    return (
        <div className="bfg-loader">
            <span className="bfg-loader__element"></span>
            <span className="bfg-loader__element"></span>
            <span className="bfg-loader__element"></span>
        </div>
    )
}

export default Loader;