import axios from "axios";
import { LoginInput } from "../Interfaces/Auth";
import { API_URL } from "../Config/env";

class AuthService {
    login = async (data: LoginInput) => {
        return await axios.post(`${API_URL}/auth/login`, data);
    };
}

const authService = new AuthService();
export default authService;