export enum ActionConstants {
    SET_FORM_DATA = "SET_FORM_DATA_BOOKING",
    CLEAR_FORM_DATA = "CLEAR_FORM_DATA_BOOKING",
    OPEN_DATE_SELECT = "OPEN_DATE_SELECT_BOOKING",
    CLOSE_DATE_SELECT = "CLOSE_DATE_SELECT_BOOKING",
    SET_FORM_STEP = "SET_FORM_STEP_BOOKING",
    SET_FORM_ERROR = "SET_FORM_ERROR_BOOKING",
    CLEAR_FORM_ERROR = "CLEAR_FORM_ERROR_BOOKING",
    CLEAR = "CLEAR_BOOKING",
    SET_LIST = "SET_LIST_BOOKING",
}
