import React from "react";

import "./styles.scss";

interface Props {
    selected: boolean;
    title: string;
    onClick: VoidFunction;
}

const OptionChip: React.FC<Props> = (props) => {
    const { onClick, selected, title } = props;

    return (
        <div className={`bfs-opt-chip ${selected ? 'selected' : undefined}`} onClick={onClick}>
            <span>{title}</span>
        </div>
    )
}

export default OptionChip;