import { FormError } from "../Hooks/useForm";

export const getDefaultFormErrors = <T extends object>(initialState: T) => {
		const result: Record<string, null> = {};

		Object.keys(initialState).map((key) => {
			result[key] = null;
			return;
		});

		return result as FormError<T>;
	};