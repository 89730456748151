import produce from 'immer';
import { ActionConstants } from './action-constants';
import { BookingActionTypes } from './action-types';
import { Booking, BookingFormStep, BookingInput } from '../../Interfaces/Booking';
import { FormError } from '../../Hooks/useForm';
import { getDefaultFormErrors } from '../../Utils';

const INITIAL_FORM_DATA: BookingInput = {
    service_type: "house_cleaning",
    frequency: "one_time",
    extra_services: [],
    email: "",
    tips: "0%",
    parking: "0",
    key_info: "someone_will_be_at_home",
    user_exists: false
}

interface initialStateInterface {
    step: BookingFormStep;
    form: BookingInput;
    formErrors: FormError<BookingInput>;
    openSelectDateModal: boolean;
    data: {bookings: Booking[], totalCount: number; };
}

const initialState: initialStateInterface = {
    step: "details",
    form: INITIAL_FORM_DATA,
    openSelectDateModal: false,
    formErrors: getDefaultFormErrors<BookingInput>(INITIAL_FORM_DATA),
    data: {
        bookings: [],
        totalCount: 0
    }
};

export const bookingReducer = (
	state: initialStateInterface = initialState,
	Action: BookingActionTypes
): initialStateInterface => {
	return produce(state, (bookingData) => {
		switch (Action.type) {
			case ActionConstants.SET_FORM_DATA:
				bookingData.form = { ...bookingData.form, [Action.payload.key]: Action.payload.value };
				return bookingData;

			case ActionConstants.CLEAR_FORM_DATA:
				bookingData.form = INITIAL_FORM_DATA;
				return bookingData;

            case ActionConstants.OPEN_DATE_SELECT:
                bookingData.openSelectDateModal = true;
                return bookingData;

            case ActionConstants.CLOSE_DATE_SELECT:
                bookingData.openSelectDateModal = false;
                return bookingData;

            case ActionConstants.SET_FORM_STEP:
                bookingData.step = Action.payload;
                return bookingData;

            case ActionConstants.SET_FORM_ERROR:
                bookingData.formErrors = {
                    ...bookingData.formErrors,
                    [Action.payload.key]: Action.payload.value,
                }
                return bookingData;

            case ActionConstants.CLEAR_FORM_ERROR:
                bookingData.formErrors = getDefaultFormErrors<BookingInput>(INITIAL_FORM_DATA);
                return bookingData;

            case ActionConstants.CLEAR:
                bookingData = { ...bookingData, ...initialState };
                return bookingData;

            case ActionConstants.SET_LIST:
                bookingData.data = Action.payload;
                return bookingData;

			default:
				return bookingData;
		}
	});
};
