import axios from 'axios';
import { API_URL } from '../Config/env';
import { GetQuoteInput } from '../Interfaces/GetQuote';

class PublicService {
	submit_quote_request = (data: GetQuoteInput) => {
		return axios.post(`${API_URL}/public/request-quote`, data);
	};
}

const publicService = new PublicService();
export default publicService;
