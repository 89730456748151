import produce from 'immer';
import { ActionConstants } from './action-constants';
import { AuthActionTypes } from './action-types';
import { BfsToken, User } from '../../Interfaces/User';

interface initialStateInterface {
	user_data: User | null;
	tokens: BfsToken | null;
}

const initialState: initialStateInterface = {
	user_data: null,
	tokens: null,
};

export const authReducer = (
	state: initialStateInterface = initialState,
	Action: AuthActionTypes
): initialStateInterface => {
	return produce(state, (auth) => {
		switch (Action.type) {
			case ActionConstants.LOGIN:
				auth.user_data = Action.payload.user;
				auth.tokens = Action.payload.tokens;
				return auth;

			case ActionConstants.LOGOUT:
				auth.user_data = null;
				auth.tokens = null;
				return auth;

			case ActionConstants.UPDATE_USER_DATA:
				auth.user_data = { ...auth.user_data, ...Action.payload };
				return auth;

			default:
				return auth;
		}
	});
};
