import React from "react";
import "./styles.scss"
import homeImg from "../../Assets/Images/home.svg";
import apartmentImg from "../../Assets/Images/apartment.svg";
import constructionImg from "../../Assets/Images/construction.svg";
import deepImg from "../../Assets/Images/deep.svg";
import condoImg from "../../Assets/Images/condo.svg";
import moveImg from "../../Assets/Images/move.svg";

const Services: React.FC = () => {

    return (
        <section id="services" className="cl-services">
            <h3>
                Discover Our <span>Premium</span> House Cleaning Services
            </h3>

            <div className="container mt-5 px-0 px-md-5">
                <div className="row gx-5 mb-5">
                    <div className="service col-md-4 col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="card-img">
                                    <img src={homeImg} alt="house" />
                                </div>
                                <h5 className="card-title">House Cleaning Services</h5>
                                <p className="card-text">Our regular cleaning service ensures your home remains consistently clean and tidy. </p>
                            </div>
                        </div>
                    </div>

                    <div className="service col-md-4 col-sm-12">
                        <div className="card">
                        <div className="card-body">
                            <div className="card-img">
                                <img src={apartmentImg} alt="house" />
                            </div>
                            <h5 className="card-title">Apartment Cleaning Services</h5>
                            <p className="card-text"> We scrub grout, remove built-up dirt and grime, to restore your home's pristine condition.</p>
                        </div>
                        </div>
                    </div>
                    
                    <div className="service col-md-4 col-sm-12">
                        <div className="card">
                        <div className="card-body">
                            <div className="card-img">
                                <img src={constructionImg} alt="house" />
                            </div>
                            <h5 className="card-title">Post-Construction Cleaning</h5>
                            <p className="card-text">Our post-construction cleaning includes debris removal, and restoring your home to its pre-construction cleanliness.</p>
                        </div>
                        </div>
                    </div>
                </div>

                <div className="row gx-5 mb-5"> 
                    <div className="service col-md-4 col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="card-img">
                                    <img src={deepImg} alt="house" />
                                </div>
                                <h5 className="card-title">Deep Cleaning Services</h5>
                                <p className="card-text">For a thorough and comprehensive cleaning experience, our deep cleaning service is ideal.</p>
                            </div>
                        </div>
                    </div>

                    <div className="service col-md-4 col-sm-12">
                        <div className="card">
                        <div className="card-body">
                            <div className="card-img">
                                <img src={moveImg} alt="house" />
                            </div>
                            <h5 className="card-title">Move Cleaning Services</h5>
                            <p className="card-text">Relocating can be stressful, so let us take care of the cleaning process.</p>
                        </div>
                        </div>
                    </div>
                    
                    <div className="service col-md-4 col-sm-12">
                        <div className="card">
                        <div className="card-body">
                            <div className="card-img">
                                <img src={condoImg} alt="house" />
                            </div>
                            <h5 className="card-title">Condo Cleaning</h5>
                            <p className="card-text">Our experienced cleaners will meticulously clean and sanitize all areas of your condo.</p>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Services;