import React, { useState } from "react";
import "./styles.scss";

import AnimateHeight from 'react-animate-height';
import { BiCollapseVertical, BiExpandVertical } from "react-icons/bi";
import { formatEnum, getExtraServicePrice } from "../../Utils";
import useBooking from "../../Hooks/useBooking";

const BookingSummary: React.FC = () => {

    const { 
        bookingForm,
        amount,
    } = useBooking();

    const [isOpen, setIsOpen] = useState(true);
    const toggle = () => setIsOpen(!isOpen);

    return (
        <div className="card p-4 bfs-booking-summary">
            <div className="head d-flex flex-row w-100 justify-content-between align-items-center">
                <h4>
                    Order Summary
                </h4>

                <div className="icon d-flex justify-content-center align-items-center" onClick={toggle}>
                    { isOpen ? <BiCollapseVertical size={18} /> : <BiExpandVertical size={18} /> }
                </div>
            </div>

            <AnimateHeight
                duration={500}
                height={isOpen ? "auto" : 0}
            >
                <div className={`content`}>
                    <hr className="mb-4" />

                    <div className="row mb-2">
                        <div className="col-4 title">Service</div>
                        <div className="col-1">:</div>
                        <div className="col-7 value">{formatEnum(bookingForm.service_type)}</div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-4 title">Frequency</div>
                        <div className="col-1">:</div>
                        <div className="col-7 value">{formatEnum(bookingForm.frequency)}</div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-4 title">Bedrooms</div>
                        <div className="col-1">:</div>
                        <div className="col-7 value">{bookingForm.no_of_bedrooms}</div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-4 title">Bathroooms</div>
                        <div className="col-1">:</div>
                        <div className="col-7 value">{bookingForm.no_full_bathrooms}</div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-4 title">Square Feet</div>
                        <div className="col-1">:</div>
                        <div className="col-7 value">{bookingForm.square_feet}</div>
                    </div>

                    {bookingForm.extra_services.length > 0 && (
                        <div className="row mb-2">
                            <div className="col-4 value">
                                Extras
                            </div>
                        </div>
                    )}


                    {bookingForm.extra_services.map(({service, quantity}) => (
                        <div className="row mb-2 ms-3" key={`summary-extra-service-${service}`}>
                            <div className="col-4 title">{formatEnum(service)} {quantity > 1 && `(${quantity})`}</div>
                            <div className="col-1">:</div>
                            <div className="col-7 value">CA${getExtraServicePrice(service, quantity)}</div>
                        </div>
                    ))}

                    <hr className="mb-4" />

                    <div className="row mb-4 total">
                        <div className="col-6">TOTAL</div>
                        <div className="col-6">CA${amount}</div>
                    </div>
                </div>
            </AnimateHeight>
        </div>
    )
}

export default BookingSummary;