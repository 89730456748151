import React, { useEffect, useMemo } from "react";


import { MdCleaningServices } from "react-icons/md";
import { ImBoxRemove } from "react-icons/im";
import { BsHouseDownFill } from "react-icons/bs";
import { BiSolidFridge, BiSolidCabinet } from "react-icons/bi";
import { GiChickenOven } from "react-icons/gi";
import { FcFilingCabinet } from "react-icons/fc";
import { FaKitchenSet } from "react-icons/fa6";

import { BfsFrequency, BfsServiceType, BfsServices, BookingInput } from "../../../Interfaces/Booking";
import CheckboxRound from "../../CheckboxRound";
import InputField from "../../InputField";
import OptionChip from "../OptionChip";
import SelectField from "../../SelectField";
import ExtraService from "../ExtraService";
import useBooking from "../../../Hooks/useBooking";
import { EXTRA_SERVICES } from "../../../Utils/constants";

export const StepOne: React.FC = () => {
    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, []);
    
    const { 
        bookingForm, 
        bookingFormErrors,
        updateFormData: onChange
    } = useBooking();
    
    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) =>
        onChange(e.target.name as keyof BookingInput, e.target.value);

    const onChangeServiceType = (type: BfsServiceType) => () =>
        onChange("service_type", type);
    
    const onChangeFrequency = (frequency: BfsFrequency) => () =>
        onChange("frequency", frequency);

    const isServiceSelected = (service: BfsServices) => 
        bookingForm.extra_services.filter((e) => e.service === service)[0];

    const onExtraServiceSelect = (service: BfsServices) => () => {
        const exist = isServiceSelected(service);

        if (exist) {
            onChange("extra_services", bookingForm.extra_services.filter(e => e.service !== service));
        } else {
            onChange("extra_services", [ { service, quantity: 1 }, ...bookingForm.extra_services ])
        }
    }

    const onChangeExtraServiceQuantity = (service: BfsServices, val: number) =>
        onChange("extra_services", bookingForm.extra_services.map(e => {
            if (e.service === service) {
                return { ...e, quantity: val }
            }
            return e;
        }));

    const extraServices = useMemo(() => {
        if (bookingForm.service_type === "house_cleaning") return EXTRA_SERVICES;

        return EXTRA_SERVICES.filter(e => e.service === "change_linens");
    }, [bookingForm.service_type]);

    return (
        <>
            <div className="card service_type d-flex flex-column align-items-start p-4 p-md-5 mb-4">
                <h4>
                    What type of service do you require?
                </h4>

                <CheckboxRound
                    label="House Cleaning"
                    checked={bookingForm.service_type === "house_cleaning"}
                    onChange={onChangeServiceType("house_cleaning")}
                />

                <CheckboxRound
                    label="Post Construction Cleaning"
                    checked={bookingForm.service_type === "post_construction_cleaning"}
                    onChange={onChangeServiceType("post_construction_cleaning")}
                />
            </div>
 
            <div className="card details p-4 p-md-5 mb-4 d-flex flex-column align-items-start">
                <h4 className="px-2 px-md-4">
                    Where will the service be taking place?
                </h4>

                <div className="p-2 p-md-4 w-100">
                    <InputField
                        label="Postal Code"
                        name="post_code"
                        error={bookingFormErrors.post_code || null}
                        value={bookingForm.post_code || ""}
                        onChange={handleChange}
                        placeholder="Postal Code"
                        required={true}
                    />
                </div>

                <hr />

                
                {bookingForm.service_type === "house_cleaning" &&  (
                    <>
                        <div className="p-2 p-md-4">
                            <label className="form-label">How Often?</label>

                            <div className="d-flex flex-row justify-content-start flex-wrap frequency-options">
                                <OptionChip
                                    title="One-Time"
                                    selected={bookingForm.frequency === "one_time"}
                                    onClick={onChangeFrequency("one_time")}
                                />

                                {/* <OptionChip 
                                    title="Every week"
                                    selected={bookingForm.frequency === "every_week"}
                                    onClick={onChangeFrequency("every_week")}
                                />

                                <OptionChip 
                                    title="Every 2 weeks"
                                    selected={bookingForm.frequency === "every_2_weeks"}
                                    onClick={onChangeFrequency("every_2_weeks")}
                                />

                                <OptionChip 
                                    title="Every 4 weeks"
                                    selected={bookingForm.frequency === "every_4_weeks"}
                                    onClick={onChangeFrequency("every_4_weeks")}
                                /> */}
                            </div>
                        </div>

                        <hr />
                    </>
                )}

                <h4 className="px-2 px-md-4">{bookingForm.service_type === "house_cleaning" ? 
                    "Select Items that requires cleaning" : "How Big Is Your Space?"}
                </h4>

                <div className="row mb-2 p-2 p-md-4 g-4 w-100">
                    {bookingForm.service_type === "house_cleaning" && <div className="col-12 col-md-6">
                        <SelectField
                            error={bookingFormErrors.no_of_bedrooms || null}
                            name="no_of_bedrooms"
                            onChange={(name, value) => onChange(name, value)}
                            label="Bedrooms"
                            value={bookingForm.no_of_bedrooms?.toString() || ""}
                            options={[
                                {label: "1", value: "1"},
                                {label: "2", value: "2"},
                                {label: "3", value: "3"},
                                {label: "4", value: "4"},
                                {label: "5", value: "5"},
                                {label: "6", value: "6"}
                            ]}
                        />
                    </div>}

                    {bookingForm.service_type === "house_cleaning" && <div className="col-12 col-md-6">
                        <SelectField 
                            error={bookingFormErrors.no_full_bathrooms || null}
                            name="no_full_bathrooms"
                            onChange={(name, value) => onChange(name, value)}
                            label="Full Bathrooms"
                            value={bookingForm.no_full_bathrooms?.toString() || ""}
                            options={[
                                {label: "0", value: "0"},
                                {label: "1", value: "1"},
                                {label: "2", value: "2"},
                                {label: "3", value: "3"},
                                {label: "4", value: "4"},
                                {label: "5", value: "5"}
                            ]}
                        />
                    </div>}

                    <div className="col-12 col-md-6">
                        <InputField 
                            type="number"
                            error={bookingFormErrors.square_feet || null}
                            name="square_feet"
                            value={bookingForm.square_feet?.toString() || ""}
                            onChange={handleChange}
                            label="Square Feet"
                            min={300}
                        />
                    </div>
                </div>

                <hr />

                <h4 className="px-2 px-md-4">Select extra services</h4>

                <div className="row p-2 p-md-4 w-100 g-4 services">
                    {
                        extraServices.map((item) => (
                            <div key={item.service} className="col-6 col-md-4 col-xl-3">
                                <ExtraService 
                                    title={item.title}
                                    single={typeof item.single === "undefined" ? true : item.single}
                                    onSelect={onExtraServiceSelect(item.service)}
                                    selected={Boolean(isServiceSelected(item.service))}
                                    icon={<item.icon size="35px" />}
                                    service={item.service}
                                    extra_services={bookingForm.extra_services}
                                    setQuantity={(val) => onChangeExtraServiceQuantity(item.service, val)}
                                />
                            </div>
                        ))
                    }
                </div>

                <hr />
                
                <div className="row p-2 p-md-4 w-100">
                    <div className="col-12 col-lg-6">
                        <InputField 
                            name="email"
                            value={bookingForm.email}
                            onChange={handleChange}
                            error={bookingFormErrors.email}
                            label="Email address"
                            placeholder="Eg: john@bfs-cleaners.com"
                        />
                    </div>
                </div>
            </div>
        </>
    );
}