import { BiArrowBack } from "react-icons/bi";
import useBooking from "../../../Hooks/useBooking";
import { BookingInput } from "../../../Interfaces/Booking";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import OptionChip from "../OptionChip";
import AnimateHeight from "react-animate-height";
import InputField from "../../InputField";
import { useEffect, useMemo } from "react";

export const StepTwo: React.FC = () => {
    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, []);

    const { setFormStep, bookingForm, bookingFormErrors, updateFormData: onChange } = useBooking();

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) =>
        onChange(e.target.name as keyof BookingInput, e.target.value);

    const minDate = useMemo(() => {
        const today = new Date();
        today.setDate(today.getDate() + 1);

        return today;
    }, [])

    const goBack = () => setFormStep("details");

    return (
        <>
            <button type="button" className="go-back btn mb-4" onClick={goBack}>
                <span><BiArrowBack /></span>
                {" "}
                <span>Go Back</span>
            </button>

            <div className="card service_type d-flex flex-column align-items-start p-4 p-md-5 mb-4">
                <div className="p-2 p-md-4">
                    <label className="form-label d-block">Select Date</label>
                    
                    <ReactDatePicker
                        selected={bookingForm.date ? new Date(bookingForm.date) : null} 
                        onChange={(date) => onChange("date", date?.toISOString())}
                        className="form-control"
                        placeholderText="Select a date"
                        required
                        minDate={minDate}
                    />
                </div>

                <AnimateHeight
                    height={Boolean(bookingForm.date) ? "auto" : 0}
                    duration={300}
                >
                    <div className="p-2 p-md-4">
                        <label className="form-label d-block">Select Time Slot</label>

                        <div className="d-flex flex-wrap time-slots">
                            <OptionChip title="8:00 AM" selected={bookingForm.time === "8"} onClick={() => onChange("time", "8")} />

                            <OptionChip title="9:00 AM" selected={bookingForm.time === "9"} onClick={() => onChange("time", "9")} />

                            <OptionChip title="10:00 AM" selected={bookingForm.time === "10"} onClick={() => onChange("time", "10")} />

                            <OptionChip title="11:00 AM" selected={bookingForm.time === "11"} onClick={() => onChange("time", "11")} />

                            <OptionChip title="12:00 PM" selected={bookingForm.time === "12"} onClick={() => onChange("time", "12")} />

                            <OptionChip title="1:00 PM" selected={bookingForm.time === "13"} onClick={() => onChange("time", "13")} />

                            <OptionChip title="2:00 PM" selected={bookingForm.time === "14"} onClick={() => onChange("time", "14")} />

                            <OptionChip title="3:00 PM" selected={bookingForm.time === "15"} onClick={() => onChange("time", "15")} />

                            <OptionChip title="4:00 PM" selected={bookingForm.time === "16"} onClick={() => onChange("time", "16")} />

                            <OptionChip title="5:00 PM" selected={bookingForm.time === "17"} onClick={() => onChange("time", "17")} />

                            <OptionChip title="6:00 PM" selected={bookingForm.time === "18"} onClick={() => onChange("time", "18")} />
                        </div>
                    </div>
                </AnimateHeight>

                <hr />

                <h4 className="px-2 px-md-4">Tips & Parking (Optional)</h4>

                <div className="p-2 p-md-4">
                    <label className="form-label">Tips</label>

                    <div className="d-flex flex-wrap tips">
                        <OptionChip 
                            selected={bookingForm.tips === "0%"} 
                            onClick={() => onChange("tips", "0%")} 
                            title="0%" 
                        />

                        <OptionChip 
                            selected={bookingForm.tips === "10%"} 
                            onClick={() => onChange("tips", "10%")} 
                            title="10%"
                        />

                        <OptionChip 
                            selected={bookingForm.tips === "15%"} 
                            onClick={() => onChange("tips", "15%")} 
                            title="15%" 
                        />

                        <OptionChip 
                            selected={bookingForm.tips === "20%"} 
                            onClick={() => onChange("tips", "20%")} 
                            title="20%" 
                        />

                        <OptionChip
                            selected={bookingForm.tips === "Other"} 
                            onClick={() => onChange("tips", "Other")} 
                            title="Other" 
                        />
                    </div>

                    <AnimateHeight
                        height={bookingForm.tips === "Other" ? "auto" : 0}
                        duration={300}
                    >
                        <div className="mt-4">
                            <InputField
                                type="number"
                                error={bookingFormErrors.tips || null}
                                value={bookingForm.otherTip?.toString() || ""}
                                onChange={handleChange}
                                name="otherTip"
                                min={0}
                                max={100}
                                prepend="%"
                            />    
                        </div>
                    </AnimateHeight>
                </div>

                <hr />

                <div className="p-2 p-md-4">
                    <label className="form-label">Parking</label>

                    <div className="d-flex flex-wrap parking">
                        <OptionChip 
                            selected={bookingForm.parking === "0"} 
                            onClick={() => onChange("parking", "0")} 
                            title="$0" 
                        />

                        <OptionChip 
                            selected={bookingForm.parking === "5"} 
                            onClick={() => onChange("parking", "5")} 
                            title="$5"
                        />

                        <OptionChip 
                            selected={bookingForm.parking === "10"} 
                            onClick={() => onChange("parking", "10")} 
                            title="$10" 
                        />

                        <OptionChip 
                            selected={bookingForm.parking === "20"} 
                            onClick={() => onChange("parking", "20")} 
                            title="$20" 
                        />

                        <OptionChip
                            selected={bookingForm.parking === "Other"} 
                            onClick={() => onChange("parking", "Other")} 
                            title="Other" 
                        />
                    </div>

                    <AnimateHeight
                        height={bookingForm.parking === "Other" ? "auto" : 0}
                        duration={300}
                    >
                        <div className="mt-4">
                            <InputField
                                type="number"
                                error={bookingFormErrors.parking || null}
                                value={bookingForm.otherParking?.toString() || ""}
                                onChange={handleChange}
                                name="otherParking"
                                min={0}
                                prepend="$"
                            />    
                        </div>
                    </AnimateHeight>
                </div>

                <hr />
                
                {!bookingForm.user_exists && <>
                    <h4 className="px-2 px-md-4">Customer Details</h4>

                    <div className="p-2 p-md-4 w-100">
                        <div className="row gy-4 mb-4">
                            <div className="col-12 col-md-6">
                                <InputField 
                                    label="First name"
                                    error={bookingFormErrors.firstname || null}
                                    value={bookingForm.firstname || ""}
                                    onChange={handleChange}
                                    name="firstname"
                                    placeholder="Eg: John"
                                />
                            </div>

                            <div className="col-12 col-md-6">
                                <InputField 
                                    label="Last name"
                                    error={bookingFormErrors.lastname || null}
                                    value={bookingForm.lastname || ""}
                                    onChange={handleChange}
                                    name="lastname"
                                    placeholder="Eg: Fish"
                                />
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-12 col-md-6">
                                <InputField 
                                    label="Email"
                                    error={bookingFormErrors.email || null}
                                    value={bookingForm.email || ""}
                                    onChange={handleChange}
                                    name="email"
                                    placeholder="Eg: john@bfs-cleaners.com"
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-md-6">
                                <InputField 
                                    label="Phone"
                                    error={bookingFormErrors.phone || null}
                                    value={bookingForm.phone || ""}
                                    onChange={handleChange}
                                    name="phone"
                                    placeholder="Phone No."
                                />
                            </div>
                        </div>
                    </div>

                    <hr />
                
                    <h4 className="px-2 px-md-4">Address Details</h4>

                    <div className="p-2 p-md-4 w-100">
                        <div className="row gy-4 mb-4">
                            <div className="col-12 col-md-6">
                                <InputField 
                                    label="Address"
                                    error={bookingFormErrors.address || null}
                                    value={bookingForm.address || ""}
                                    onChange={handleChange}
                                    name="address"
                                    placeholder="123 North"
                                />
                            </div>

                            <div className="col-12 col-md-6">
                                <InputField 
                                    label="Apt. No."
                                    error={bookingFormErrors.apt_no || null}
                                    value={bookingForm.apt_no || ""}
                                    onChange={handleChange}
                                    name="apt_no"
                                    placeholder="#"
                                />
                            </div>
                        </div>
                    </div>

                    <hr />
                </>}

                <h4 className="px-2 px-md-4">Key Information</h4>

                <div className="p-2 p-md-4 w-100">
                    <div className="d-flex flex-wrap flex-row key_info">
                        <OptionChip 
                            title="Someone Will Be At Home"
                            selected={bookingForm.key_info === "someone_will_be_at_home"}
                            onClick={() => onChange("key_info", "someone_will_be_at_home")}
                        />

                        <OptionChip 
                            title="I Will Hide The Keys"
                            selected={bookingForm.key_info === "i_will_hide_the_keys"}
                            onClick={() => onChange("key_info", "i_will_hide_the_keys")}
                        />
                    </div>
                </div>

                <div className="p-2 p-md-4 w-100">
                    <InputField 
                        error={bookingFormErrors.note || null}
                        value={bookingForm.note || ""}
                        name="note"
                        onChange={handleChange}
                        label="Customer Note For Provider"
                        multiline
                    />
                </div>
            </div>

            <p>
                Our prices are based on the assumed cleanliness level and the effort required for cleaning. For Deep Cleans and Heavy Duty Cleans, if we find that we need more time than estimated, we may adjust the price or prioritize tasks based on your preferences.
                <br /><br />
                By clicking the Book Now button, you are agreeing to our <a href="#">Terms of Service</a> and <a href="#">Privacy Policy </a>.
            </p>
        </>
    )
}