import React from "react"
import { BfsPaymentStatus, Booking } from "../Interfaces/Booking";
import DataTable from "react-data-table-component";
import { formatDDMMYY, formatEnum, formatTime } from "../Utils";
import { useManageBooking } from "../Hooks/useManageBooking";
import { Link } from "react-router-dom";
import { ROUTES } from "../Router/routes";

const getPaymentStatusVariant = (status: BfsPaymentStatus) => {
    switch (status) {
        case "Success":
            return "success";

        case "Failed":
            return "danger";

        case "Pending":
            return "warning";
    
        default:
            return "secondary";
    }
}

interface BookingTableColumn {
    name: string | JSX.Element;
    selector: (row: Booking) => any;
}

const columns: BookingTableColumn[] = [
    {
        name: "Booking ID",
        selector: row => row.booking_id
    },
    {
        name: "Scheduled Date",
        selector: row => formatDDMMYY(row.date)
    },
    {
        name: "Time",
        selector: row => formatTime(row.time)
    },
    {
        name: "Service Type",
        selector: row => formatEnum(row.service_type)
    },
    {
        name: <span>Square Feet (Ft<sup>2</sup>)</span>,
        selector: row => row.square_feet
    },
    {
        name: "Amount",
        selector: row => `$${row.amount.toFixed(2)}`
    },
    {
        name: "Payment Status",
        selector: row => (
            <span className={`badge payment-status p-2 bg-${getPaymentStatusVariant(row.payment_status)}`}>
                {row.payment_status}
            </span>
        )
    }
]

const customStyles = {
    rows: {
        style: {
            minHeight: '72px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            fontSize: 16
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
            fontSize: 16,
            textTransform: "capitalize"
        },
    },
    pagination: {
        style: {
            fontSize: 16
        }
    }
};

export const ManageBooking: React.FC = () => {
    const {
        bookings,
        fetching,
        totalCount,
        handlePerRowsChange,
        handlePageChange
    } = useManageBooking();

    return (
        <div className="bfs-manage-booking p-4">
            <div className="container mt-4 card p-4 bookings">
                <div className="w-100 actions d-flex flex-row flex-wrap justify-content-end align-items-center mb-2">
                    <div>
                        <input 
                            type="text" 
                            className="form-control" 
                            placeholder="Search" 
                            disabled
                        />
                    </div>
                    
                    <div>
                        <select 
                            name="tableContextActions" 
                            id="tableContextActions"
                            className="form-select"
                            value={""}
                        >
                            <option value="" selected disabled>Booking Actions</option>
                            <option value="edit">Edit</option>
                            <option value="delete">Delete</option>
                            <option value="archive">Archive</option>
                        </select>
                    </div>

                    <div>
                        <Link to={ROUTES.book.index} className="btn">Book now</Link>
                    </div>
                </div>

                <DataTable 
                    columns={columns}
                    data={bookings}
                    customStyles={customStyles}
                    progressPending={fetching}
                    progressComponent={<div className="spinner-border" role="status" />}
                    paginationTotalRows={totalCount}
                    onChangeRowsPerPage={handlePerRowsChange}
			        onChangePage={handlePageChange}
                    selectableRows
                    pagination
                    paginationServer
                    pointerOnHover
                    highlightOnHover
                    striped
                />
            </div>
        </div>
    );
}