import AboutUs from "../Components/About";
import Contact from "../Components/Contact";
import Hero from "../Components/Hero";
import Reviews from "../Components/Reviews";
import Services from "../Components/Services";
import scrollTopImg from "../Assets/Images/scrollTop.png";
import { CSSTransition } from "react-transition-group";
import { useScrollPosition } from "../Hooks/useScrollPosition";
import { useRef } from "react";



export const Home: React.FC = () => {
    const scrollPosition = useScrollPosition();
    const upRef = useRef(null);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    }

    return (
        <>
            <Hero />
            
            <AboutUs />

            <Services />

            <Reviews />

            <Contact />

            <CSSTransition 
                in={scrollPosition > 1500}
                unmountOnExit
                nodeRef={upRef}
                classNames={"scrollTop"}
                timeout={500}
            >
                <a ref={upRef} className="scrollTop" onClick={scrollToTop}>
                    <img src={scrollTopImg} />
                </a>
            </CSSTransition>
        </>
    )
}